import React from "react"
import { Link } from "gatsby"

import withData from "../components/withData"
import Layout from "../components/layout"
import SEO from "../components/seo"

class IndexPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = { searchTerm: "" }
  }

  onChange = event => {
    this.setState({ searchTerm: this.sanitizeSearchTerm(event.target.value) })
  }

  sanitizeSearchTerm = term => {
    return term
      ? term
          .toLowerCase()
          .replace(/[^0-9a-z ]/gi, "")
          .trim()
      : ""
  }

  render() {
    let words = this.props.data

    if (this.state.searchTerm) {
      words = words.filter(word => {
        return (
          this.sanitizeSearchTerm(word.node.meaning).includes(
            this.state.searchTerm
          ) ||
          this.sanitizeSearchTerm(word.node.word).includes(
            this.state.searchTerm
          )
        )
      })
    }

    return (
      <Layout>
        <SEO />
        <div>
          <Link to={`/quiz`} style={{ color: `#e30513` }}>
            Zum Appenzeller Quiz
          </Link>
          <input
            style={{
              width: `100%`,
              padding: `1rem .5rem`,
              margin: `2rem 0`,
              border: `1px solid #ccc`,
            }}
            type="text"
            className="input"
            id="searchTerm"
            placeholder="Nach einem Begriff suchen..."
            onChange={this.onChange.bind(this)}
          />

          {words.length ? (
            <table>
              <thead>
                <tr>
                  <th>Wort</th>
                  <th style={{ width: `50%` }}>Bedeutung</th>
                </tr>
              </thead>
              <tbody>
                {words.map(({ node }) => {
                  return (
                    <tr key={node.id}>
                      <td>
                        <Link
                          to={`/${node.slug}`}
                          style={{
                            fontWeight: 700,
                          }}
                        >
                          {node.word}
                        </Link>
                      </td>
                      <td>
                        <Link to={`/${node.slug}`}>{node.meaning}</Link>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          ) : (
            <p>
              Keine Begriffe gefunden für <b>»{this.state.searchTerm}«</b>
            </p>
          )}
        </div>
      </Layout>
    )
  }
}

export default withData(IndexPage)
